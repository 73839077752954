import useFontFaceObserver from "use-font-face-observer";
import rip from "./images/rip.webp";
import texture from "./images/texture.webp";
import vid from "./images/hero.mp4";
import vidMb from "./images/hero-mb.mp4";
import poster from "./images/poster.jpg";
import {
  FaFacebook,
  FaInstagram,
  FaSpotify,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaPodcast } from "react-icons/fa6";
import { useEffect } from "react";

import "./App.css";
import { useState } from "react";

function App() {
  const webFontsLoaded = useFontFaceObserver([{ family: `Text` }]);
  const [mobile, setMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    const app = document.querySelector(".app");

    if (webFontsLoaded) {
      setTimeout(() => {
        app.classList.add("opacity-1");
      }, 500);
    }
  }, [webFontsLoaded]);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://substackapi.com/widget.js";
    script.async = true;

    document.body.appendChild(script);

    window.CustomSubstackWidget = {
      substackUrl: "theoverlapnewsletter.substack.com",
      placeholder: "example@gmail.com",
      buttonText: "Sign Up",
      colors: {
        primary: "#FFFFFF",
        input: "#000000",
        email: "#FFFFFF",
        text: "#000000",
      },
    };

    const detect = () => {
      window
        .matchMedia("(max-width: 768px)")
        .addEventListener("change", (e) => setMobile(e.matches));
    };

    window.addEventListener("resize", () => {
      detect();
    });
  }, []);

  return (
    <div className="app">
      <div className="app__text">
        <svg
          className="logo"
          width="186"
          height="81"
          viewBox="0 0 186 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M53.8935 0.00171881C53.6498 1.88198 53.4348 3.76567 53.1556 5.64251C52.2229 11.9324 51.2666 18.2205 50.3187 24.5086C48.9053 33.8946 47.4903 43.2821 46.0803 52.6679C45.4574 56.8114 44.8344 60.9548 44.242 65.1018C44.154 65.7114 43.9729 66.0102 43.311 65.9999C40.9889 65.9724 38.6649 65.974 36.3392 66.0015C35.7755 66.0084 35.6603 65.7508 35.5893 65.2614C34.9817 61.118 34.3469 56.9745 33.719 52.8346C32.6711 45.9214 31.6184 39.0098 30.5757 32.0966C29.7158 26.4062 28.8627 20.7121 28.0114 15.0181C27.9725 14.7553 27.9691 14.484 27.9453 14.1698H36.4661C37.5951 23.918 38.724 33.6661 39.8853 43.6908C40.2152 41.3537 40.52 39.2811 40.7941 37.2051C41.7352 30.0636 42.6645 22.9203 43.5989 15.7787C44.2877 10.5175 44.9783 5.25958 45.6673 0H53.8919L53.8935 0.00171881Z"
            fill="white"
          />
          <path
            d="M8.6494 0.67334V2.6847H5.63657V10.1021H3.11441V2.67094H0V0.67334H8.6494Z"
            fill="white"
          />
          <path
            d="M103.572 63.1984C103.363 62.114 103.246 60.9951 103.225 59.8885C103.138 55.4306 103.247 50.9607 103.003 46.5133C102.827 43.3559 101.833 40.4612 98.3605 39.1709C98.5329 39.0731 98.6066 39.0234 98.6868 38.9873C101.347 37.7863 102.577 35.5264 103.073 32.7398C103.662 29.418 103.631 26.084 103.133 22.7569C102.706 19.9136 101.677 17.4084 99.1657 15.823C97.1718 14.5635 94.9217 14.2238 92.6383 14.1826C89.1646 14.1208 85.6892 14.1517 82.2119 14.1466C81.8688 14.1466 81.5257 14.1775 81.1724 14.1929V65.8975H89.4357V43.1621C90.0033 43.1621 90.4437 43.1603 90.8806 43.1638C92.496 43.1759 93.3766 43.7163 93.8922 45.2915C94.2421 46.3621 94.5199 47.5083 94.5485 48.6289C94.6489 52.3643 94.5519 56.1066 94.6774 59.8388C94.7327 61.4721 95.0942 63.1057 95.4157 64.7186C95.5948 65.6264 96.2728 66.0279 97.1952 66.0004C98.106 65.9729 99.0217 65.9953 99.9341 65.9953H104.459C104.114 64.94 103.741 64.0906 103.57 63.2L103.572 63.1984ZM94.9972 36.628H89.4475V22.6626H94.9972V36.628Z"
            fill="white"
          />
          <path
            d="M22.6065 28.2792C22.6013 25.6189 22.3103 22.9806 21.4987 20.4316C20.4981 17.2974 18.5498 15.1518 15.2299 14.5185C12.7064 14.0361 10.1642 14.0121 7.64237 14.4858C4.24762 15.1227 2.17676 17.2373 1.13366 20.5072C0.289656 23.1523 0.0327108 25.8711 0.013993 28.6243C-0.00982972 32.5139 0.00888833 36.4051 0.00888833 40.2979H0.013993C0.013993 44.3696 -0.026845 48.4426 0.0310102 52.5158C0.0684459 55.2604 0.325389 57.9931 1.20172 60.6262C2.3248 63.992 4.55222 66.1479 8.0967 66.5101C10.3122 66.7366 12.589 66.7281 14.8028 66.4964C17.6921 66.1925 19.7579 64.5155 20.9456 61.8207C22.1708 59.0401 22.552 56.0741 22.5757 53.0806C22.637 44.8157 22.625 36.5476 22.6065 28.2827V28.2792ZM14.2327 57.1776V58.1646H8.61738V22.3953C10.3701 22.3953 12.0989 22.3868 13.8244 22.4124C13.9571 22.4143 14.1459 22.6357 14.2038 22.7935C14.2736 22.9806 14.231 23.2089 14.231 23.4217C14.2327 34.6732 14.2327 45.9245 14.2327 57.1776Z"
            fill="white"
          />
          <path
            d="M159.586 79.8375C158.998 76.0918 158.402 72.3481 157.817 68.6007C156.351 59.2351 154.892 49.8676 153.425 40.4986C152.171 32.5014 150.91 24.5044 149.65 16.5072C149.532 15.7681 149.633 14.7253 149.203 14.3755C148.72 13.9862 147.742 14.2057 146.981 14.1988C145.026 14.1851 143.075 14.1937 141.118 14.1937C138.428 31.4742 135.752 48.6552 133.069 65.8842H141.255C141.31 65.512 141.374 65.1621 141.413 64.8087C141.643 62.684 141.885 60.5591 142.081 58.4289C142.133 57.8802 142.273 57.6349 142.87 57.6486C144.503 57.6846 146.138 57.683 147.769 57.6486C148.341 57.6383 148.518 57.8408 148.587 58.4101C149.214 63.5191 149.877 68.6248 150.525 73.7321C150.827 76.1124 151.119 78.4929 151.407 80.8184H159.682C159.639 80.3725 159.625 80.1016 159.583 79.8357L159.586 79.8375ZM143.08 49.4183C143.748 43.7553 144.409 38.1524 145.07 32.5477C145.134 32.5426 145.198 32.5357 145.262 32.5289C145.915 38.1233 146.568 43.721 147.231 49.4167L143.08 49.4183Z"
            fill="white"
          />
          <path
            d="M184.743 21.3347C184.122 18.5103 182.589 16.2201 179.816 15.2253C178.161 14.6325 176.349 14.3096 174.593 14.2339C171.026 14.0776 167.448 14.1721 163.875 14.1669C163.616 14.1652 163.356 14.1893 163.009 14.2047V66.0018H171.305V44.9329C172.901 44.833 174.417 44.8159 175.914 44.627C180.388 44.0635 183.514 41.7767 184.657 37.1979C185.973 31.9268 185.907 26.6162 184.743 21.3347ZM176.997 36.5554C176.997 36.5777 176.956 36.5967 176.873 36.6738H171.327V22.5925C173.114 22.5925 174.886 22.5822 176.658 22.6131C176.788 22.6165 177.026 22.9224 177.026 23.0873C177.032 27.5767 177.015 32.0644 176.997 36.5554Z"
            fill="white"
          />
          <path
            d="M74.5176 56.0561V66.0018H57.2188V14.1433H74.2705V24.051H65.5914V34.9801H73.8372V43.7151H65.5998V56.0561H74.5176Z"
            fill="white"
          />
          <path
            d="M127.746 56.0888V66.0018H109.782V14.1433H118.045V56.0888H127.746Z"
            fill="white"
          />
          <path
            d="M19.9599 0.67334V10.0573H17.5867V6.37303H13.7384V10.1021H11.3105V0.695751H13.7111V4.19213H17.5355V0.67334H19.9599Z"
            fill="white"
          />
          <path
            d="M31.2722 8.2508V10.1023H23.2881V0.673462H31.1484V2.58382H25.8344V4.30711H30.4773V6.25732H25.8414V8.2508H31.2722Z"
            fill="white"
          />
        </svg>

        <h1>Coming Soon</h1>
        <div className="socials">
          <a
            href="https://www.facebook.com/profile.php?id=100090262142896"
            target="_blank"
          >
            <FaFacebook />
          </a>
          <a href="https://instagram.com/wearetheoverlap" target="_blank">
            <FaInstagram />
          </a>
          <a href="https://x.com/wearetheoverlap" target="_blank">
            <FaXTwitter />
          </a>
          <a href="https://www.youtube.com/@TheOverlap" target="_blank">
            <FaYoutube />
          </a>
          <a href="https://tiktok.com/@wearetheoverlap" target="_blank">
            <FaTiktok />
          </a>
          <a
            href="https://podcasts.apple.com/us/podcast/the-overlap-with-gary-neville/id1568471572?app=podcast&at=1000lHKX&ct=linktree_http&itscg=30200&itsct=lt_p&ls=1&mt=2"
            target="_blank"
          >
            <FaPodcast />
          </a>
          <a
            href="https://open.spotify.com/show/7eyJgU9qdRS91Hy2otbEUQ"
            target="_blank"
          >
            <FaSpotify />
          </a>
        </div>
      </div>

      <div className="mailing">
        <h2>Sign up to our mailing list, and be the first to be notified</h2>
        <div className="wrap">
          <div id="custom-substack-embed"></div>

          {/* <form>
            <input type="email" />
            <input type="submit" value="sign up" />
          </form> */}
        </div>
      </div>

      <img className="texture" src={texture} alt="Image texture"></img>
      <img className="rip" src={rip} alt="ripped paper texture"></img>
      {mobile ? (
        <video
          src={vidMb}
          poster={poster}
          autoPlay
          playsInline
          muted
          loop
        ></video>
      ) : (
        <video src={vid} autoPlay playsInline muted loop></video>
      )}
    </div>
  );
}

export default App;
